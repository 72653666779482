import * as React from "react"

function AddressIcon(props) {
    return (
        <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.5 12C11.1569 12 12.5 10.6569 12.5 9C12.5 7.34315 11.1569 6 9.5 6C7.84315 6 6.5 7.34315 6.5 9C6.5 10.6569 7.84315 12 9.5 12Z" stroke="#FF5A30" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.5 21C13.5 17 17.5 13.4183 17.5 9C17.5 4.58172 13.9183 1 9.5 1C5.08172 1 1.5 4.58172 1.5 9C1.5 13.4183 5.5 17 9.5 21Z" stroke="#FF5A30" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}
export default AddressIcon